<template>
  <b-card class="mb-4 text--black" style="text-align: left;">
    <template #header>
      <div class="d-inline h4">Details</div>
      <div class="float-right"><slot></slot></div>
    </template>
    <b-row>
      <b-col cols="12" sm="6" md="4">
        <b>First Name:</b> {{user.firstname}}
      </b-col>
      <b-col cols="12" sm="6" md="4">
        <b>Last Name:</b> {{ user.lastname }}
      </b-col>
      <b-col cols="12" sm="6" md="4">
        <b>Email:</b> {{ user.email }}
      </b-col>
      <b-col cols="12" sm="6" md="4">
        <b>Last Login:</b> {{ user.lastlogin ? user.lastlogin : 'Never' }}
      </b-col>
      <b-col cols="12" sm="6" md="4">
        <b>Needs Password Change:</b> {{ user.forcepwchange == 1 ? 'Yes' : 'No' }}
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
export default {
  data () {
    return {}
  },

  props: {
    user: {
      type: Object,
      default: () => { return {} }
    }
  }
}
</script>
