<template>
  <b-container fluid class="text--black">
    <b-row>
      <b-col cols="12" md="4" class="mb-4 text-left" style="color: white;">
        <div class="text-left py-1">
          <b-button class="back-button" @click="$router.go(-1)" variant="light"><b-icon icon="arrow-left"></b-icon></b-button>
        </div>
      </b-col>
      <b-col cols="12" md="4" class="mb-4">
        <b-button
          @click="disable()"
          v-if="user.active == 1"
          variant="danger"
          block
        >
          <b-icon icon="x-circle"></b-icon>
          Disable
        </b-button>
        <b-button
          @click="enable()"
          v-else
          variant="info"
          block
        >
          <b-icon icon="arrow-clockwise"></b-icon>
          Re-activate
        </b-button>
      </b-col>
      <b-col cols="12" md="4" class="mb-4">
        <b-button
          @click="resetPassword()"
          variant="success"
          block
        >
          <b-icon icon="key-fill"></b-icon>
          Reset Password
        </b-button>
      </b-col>
    </b-row>
    <UserDetails
      :user="user"
    >
      <template>
        <EditUser @refresh="init()" :orgId="+orgId" :userId="+userId" edit></EditUser>
      </template>
    </UserDetails>
    <b-card class="mb-4" style="text-align: left;">
      <template #header>
        <div class="d-inline h4">Companies</div>
      </template>
      <b-row class="userrole-item">
        <b-col cols="12" md="3">
          Company
        </b-col>
        <b-col cols="12" md="3">
          Role
        </b-col>
        <b-col cols="12">
          <hr>
        </b-col>
      </b-row>
      <b-row v-for="comp in companies" :key="comp.id" class="userrole-item">
        <b-col cols="12" md="3">
          {{ comp.name }}
        </b-col>
        <b-col cols="12" md="3">
          <b-form-select v-model="comp.role">
            <b-select-option :value="null">None</b-select-option>
            <b-select-option :value="30">User</b-select-option>
            <b-select-option :value="10">Admin</b-select-option>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-button
            variant="success"
            @click="saveRoles()"
            class="float-right"
          >
            Save
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-container>
</template>
<script>
import CompanyService from '@/services/CompanyService'
import AdminService from '@/services/AdminService'

import EditUser from '@/components/admin/OrgUserModal'
import UserDetails from '@/components/admin/UserDetails'

export default {
  data () {
    return {
      organization: 'Organization',
      companies: [],
      user: {
        firstname: '',
        lastname: ''
      },
      userroleId: 0
    }
  },

  components: {
    EditUser,
    UserDetails
  },

  props: {
    userId: {
      type: [Number, String],
      default: 0
    },
    orgId: {
      type: [Number, String],
      default: 0
    }
  },

  created () {
    this.init()
  },

  methods: {
    init () {
      CompanyService.userInit(this.orgId, this.userId).then(
        (response) => {
          this.organization = response.data.info.organization
          this.user = response.data.info.user
          this.companies = []
          this.companies = response.data.info.companies
        }
      )
    },

    saveRoles () {
      const data = {
        data: this.companies,
        user_id: this.userId
      }

      CompanyService.saveUserPermission(data).then(
        (response) => {
          this.$aimNotify.notify(response)
          this.init()
        }
      )
    },

    resetPassword () {
      AdminService.resetPassword(this.userId).then(
        (response) => {
          this.$aimNotify.notify(response)
        }
      )
    },

    disable () {
      const data = {
        id: this.userId,
        active: 0
      }

      CompanyService.updateUser(data).then(
        (response) => {
          this.$aimNotify.notify(response)

          this.$nextTick(() => {
            this.init()
          })
        }
      )
      // AdminService.updateEmployee(data).then(
      //   (response) => {
      //     this.$aimNotify.notify(response)
      //     this.$nextTick(() => {
      //       this.init()
      //     })
      //   }
      // )
    },

    enable () {
      const data = {
        id: this.userId,
        active: 1
      }
      CompanyService.updateUser(data).then(
        (response) => {
          this.$aimNotify.notify(response)

          this.$nextTick(() => {
            this.init()
          })
        }
      )
    }
  }
}
</script>
